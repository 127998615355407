.modal {
    background: green;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.modal-overlay {
    position: fixed; /* Фиксированное положение */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Поверх всего контента */
  }
  
  .modal-content {
    background-color: white;
    padding: 1.875rem 2.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative; /* Для позиционирования кнопки закрытия */
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    width: 90%;
  
  }
  
  .modal-close {
    position: absolute;
    top: 20px;
    right: 30px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    color: #555;
  }
  
  .modal-title {
    margin-top: 0;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .modal-text {
      font-size: 14px;
      line-height: 1.5;
      text-align: justify;
  }
  
  .modal-text p {
    margin-bottom: 10px;
  }