.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 0px auto;
}

.container_body {
  padding: 0px 160px 40px;
  /* px-40 2xl:px-30 xl:px-20 lg:px-10 md:px-5 sm:px-1 */
}

.shrink-body {
  flex: 1 1 auto;
}

a.contacts_data-text:hover {
  text-decoration: underline;
}

.header {
  width: 100px;
  /* margin-bottom: 140px; */
}

.button_up {
  display: none;
}

.button_up.up_active {
  background-color: #cca552c0;
  transform: rotate(270deg);
  width: 35px;
  height: 35px;
  align-items: center;
  margin: auto auto;
  display: flex;
  justify-content: center;
  color: #808080;
}

.button__consult {
  border-radius: 4px;
    background-color: #CCA652;
    padding: 13px 38px;
    display: inline-block;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 400;
    transition: all .55s ease-in-out;
}
.button__thx {
  border-radius: 4px;
    background-color: #CCA652;
    padding: 13px 38px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 400;
    transition: all .55s ease-in-out;
    
}

.bg_blue {
  background: #ecf1f7;
}

.title {
  color: #202020;
  font-size: 36px;
  line-height: 42px;
  font-weight: 300;
}

.comp_title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  color: #202020;
}

.link {
  padding: 10px 16px;
  border-radius: 8px;
}

.link:hover {
 background: #CCA652;
}

.gold {
  color: #CCA652;
}

.grey {
  color: #616161;
}

.strip {
  border-top: 1px solid #d4d8df;
}

.icons {
  box-sizing: border-box;
}

.feedback_ul {
  flex-wrap: wrap;
}
.feedback_li {
  box-shadow: 8px 8px 8px #cacaca;
  margin: 10px;
  max-height: 100%;
  max-width: 200px;
}
/* .feedback_li > img {
 object-fit: cover; */
 /* max-height: fit-content; */
/* } */
.thanx_container{
  height: 100%;
  overflow: visible;
}
.thanx_container_hidden {
  max-height: 300px;
  overflow: scroll;
}

@media(max-width: 640px) {
  .link {
    padding: 6px 10px;
  }
}


.footer {
  background-color: #F2F2F2;
  margin-top: 100px;
}

.burger_menu {
  display: flex;
}