@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul, ol, li { list-style: none;}
img {vertical-align: none;}
h1,h2,h3,h4,h5,h6 {font-weight: inherit; font-size: inherit;}

body, html {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body._locked {
  overflow: hidden;
}





