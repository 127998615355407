/* DeskTop Menu */
.header__container_desktop {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 100px;
  padding: 0 80px;
  background: #f0f0f0;
  align-items: center;
  color: #4a4a4a;
}
.header_ul {
    display: flex;
}
  .header__container_mobile {
    display: none;
}
  .burger_icon {
    display: none;
}

/* Mobile Menu */
@media(max-width: 938px) {
    .header__logo-img {
        align-items: center;
        margin: auto 0;
    }
    .header__container_desktop {
        display: none;
    }
    .header__container_mobile {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        height: 80px;
        padding: 2px;
        background: #f0f0f0;
        color: #202020;
        margin: auto 0;
    }
  
    .burger_icon {
      display: flex;
    }
    .header_ul__container{
        display: none;
    }
    .header_ul__container_active{
        display: block;
    }

    .header_ul {
        display: block;
        position: absolute;
        text-align: center;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 10;
        padding: 0 80px;
        background: #f0f0f0;
        align-items: center;
        padding-top: 50px;
    }
    .header_ul > li {
        font-size: 28px;
        margin-bottom: 24px;
    }
    .link {
        font-size: 24px;
    }   
}

@media(max-width: 768px) {
    .header__container_mobile {
        padding: 18px 35px;
        margin: auto auto;
    }    
}
@media(max-width: 540px) {
    .header_ul > li {
        font-size: 24px;   
}
}
@media(max-width: 304px) {
    .header__container_mobile {
        margin: 0px auto;
        padding: 18px 25px;

    }
    .header__logo-img {
        margin: auto 0px;
    }    
    .header-mobile_contacts > img {
        display: none;
    }
}
