.About {
  /* position: relative; */
}
.about_team-kriv_ul__container {
  position: relative;
}
.about_carousel._active {
  position: absolute;
  top:30%;
  left: 0;
  z-index: 10;
  align-self: center;
  width: 50%;

}
.about_carousel {
  display: none;
}

.about_team-kriv_ul__container{
  color: #202020d2;
    margin-top: 40px;
    /* padding: 55px; */
}
.about_team-kriv_ul__container>p {
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
    align-self: center;
}
.about_team-kriv_ul {
    gap: 10px;
}
.about_team-kriv__krivtsov-title {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    max-height: 250px;
    margin-bottom: 20px;
    align-items: center;
}

.about_team-kriv_text-button {
 display: flex;
 gap: 50px;
}
.button__thanx {
  border-radius: 4px;
    background-color: #CCA652;
    padding: 10px 30px;
    display: inline-block;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 400;
    transition: all .55s ease-in-out;
    text-align: center;
}


@media(max-width: 1024px) {
  .button__thanx {
    font-size: 16px;
    padding: 10px 20px;
  }
  }


@media(max-width: 768px) {
  .about_team-kriv_text-button {
    display: block;
  }
  .button__thanx {
    margin: 20px auto;
  }
}
@media(max-width: 640px) {
    .about_team-kriv__krivtsov-title {
      gap: 15px;
  } 
  .title {
    font-weight: 300px;
  }
  .about_team-li {
    font-size: 14px;
  }
  }